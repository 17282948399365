/*
 * determines if the ordering window for a given menu is open
 * Lunch: 2pm day prior to 11am day-of
 * Breakfast: 12pm day prior to 7:15am day-of
 * Sample Sale 5am 9/1/20 - 11:59pm 9/8/20
 * Kitchen is closed on weekends - no orders accepted from Friday 11am to Sunday at 12pm
 */

import { isAfter, isBefore, isWeekend } from 'date-fns';
import getPickupDate from '../utils/get-pickup-date';

const isMenuAvailable = (menu) => {
  const pickupDate = getPickupDate();
  if (isWeekend(pickupDate)) {
    return false;
  }
  const dateInstance = new Date();
  const hours = dateInstance.getHours();
  const minutes = dateInstance.getMinutes();
  const sampleSaleStart = new Date(2020, 8, 1, 5);
  const sampleSaleEnd = new Date(2020, 8, 9, 23, 59);
  const lunchConditions =
    (hours >= 0 && hours <= 10) || (hours >= 14 && hours <= 23);
  const breakfastConditions =
    (hours >= 0 && hours <= 6) || (hours >= 12 && hours <= 23) || (hours === 7 && minutes <= 14);

  if (menu === 'LUNCH' && lunchConditions) {
    return true;
  }
  if (menu === 'BREAKFAST' && breakfastConditions) {
    return true;
  }
  if (
    menu === 'SAMPLESALE' &&
    isAfter(dateInstance, sampleSaleStart) &&
    isBefore(dateInstance, sampleSaleEnd)
  ) {
    return true;
  }
  return false;
};

export default isMenuAvailable;
