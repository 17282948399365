/* eslint-disable */
import React, {useReducer} from 'react';
import AuthContext from './auth-context.js'
import reducer from '../containers/kitchen/reducer/auth.js'

const initialState = {isAuthenticated: false}
const {localStorage, sessionStorage} = window

const getLocalStorage = (isValid, key) => {
  if (isValid === 'true') {
    const tokens = localStorage.getItem(key)
    return JSON.parse(tokens) || {}
  } 

  sessionStorage.removeItem('vs-session')
  localStorage.removeItem(key)
  return {}
}

const AuthProvider = (props) => {
  try {
    const isValid = sessionStorage.getItem('vs-session')
    const tokens = getLocalStorage(isValid, 'vs-tokens')
    const _state = isValid === "true"
      ? { ...tokens, isAuthenticated: Object.keys(tokens).length > 0} 
      : initialState;
    const [state, dispatch] = useReducer(reducer, _state)

    return (
      <AuthContext.Provider value={[state, dispatch]}>
        {props.children}
      </AuthContext.Provider> 
    )
  } catch (err) {
    console.warn(err.message)
  }
}

export default AuthProvider