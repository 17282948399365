import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import ApolloClient from 'apollo-boost';
import { ApolloProvider } from '@apollo/react-hooks';

import * as Sentry from '@sentry/react';

import { makeStyles } from '@material-ui/core/styles';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

import OrderContainer from './containers/order';
import Header from './containers/header/header.js';
import KitchenRoutes from './containers/kitchen/routes.js';

import AuthProvider from './context/auth.js';

import theme from './theme/vs.js';
import './index.css';

const apolloClient = new ApolloClient({
  uri: process.env.REACT_APP_GRAPHQL_ENDPOINT,
  request: async (operation) => {
    operation.setContext({
      headers: {
        'x-api-key': process.env.REACT_APP_API_KEY
      }
    });
  }
});

const environment = process.env.REACT_APP_BUILD_ENV || 'not-set';
// this account is tied to rdempsey@rebartechnology.com
if (environment !== 'not-set') {
  Sentry.init({
    dsn:
      'https://175cb26f4b434bf686ed00b8082c9769@o427778.ingest.sentry.io/5372310',
    release: process.env.REACT_APP_SENTRY_RELEASE,
    environment
  });
}

const useStyles = makeStyles((theme) => ({
  vs: {
    marginTop: '1em',
    marginLeft: '1em',
    marginRight: '1em'
  }
}));

const App = (props) => {
  const classes = useStyles();

  return (
    <MuiThemeProvider theme={theme}>
      <ApolloProvider client={apolloClient}>
        <Router>
          <Grid className={classes.vs}>
            <Header />
            <Switch>
              <AuthProvider>
                <Route
                  path='/kitchen'
                  render={(props) => <KitchenRoutes {...props} />}
                />
                <Route
                  path='/kitchens'
                  render={(props) => <KitchenRoutes {...props} />}
                />
                <Route
                  path='/'
                  render={(props) => <OrderContainer {...props} />}
                />
              </AuthProvider>
            </Switch>
          </Grid>
        </Router>
      </ApolloProvider>
    </MuiThemeProvider>
  );
};

ReactDOM.render(<App />, document.getElementById('root'));
