/* eslint-disable */
const { localStorage, sessionStorage } = window;

const reducer = (state, action) => {
  const { type } = action;

  switch (type) {
    case 'sample-sale-success': {
      return {
        ...state,
        isSampleSaleAuthenticated: true,
        superSecret: action.payload
      };
    }
    case 'login-success': {
      const { payload } = action;
      sessionStorage.setItem('vs-session', 'true');
      localStorage.setItem('vs-tokens', JSON.stringify(payload));

      return {
        ...payload,
        isAuthenticated: true
      };
    }
    case 'auth-challenge': {
      const { payload } = action;

      return {
        ...payload,
        isAuthenticated: false
      };
    }
    case 'auth-reset': {
      sessionStorage.removeItem('vs-session');
      localStorage.removeItem('vs-tokens');

      return {
        isAuthenticated: false
      };
    }
    default:
      return state;
  }
};

export default reducer;
