module.exports = {
  featureFlags: {
    disableManageMenu: false,
    disableAddMenuItem: false,
    disableBreakfast: false,
    disableSampleSale: false
  },
  sides: {
    lunch: [
      {
        id: 'side-french-fries',
        name: 'french fries'
      },
      { id: 'side-tater-tots', name: 'tater tots' },
      { id: 'side-macaroni-salad', name: 'macaroni salad' },
      { id: 'side-potato-salad', name: 'potato salad' },
      { id: 'side-none', name: 'no side' }
    ],
    breakfast: [
      {
        id: 'side-bacon',
        name: 'bacon (2)'
      },
      { id: 'side-sausage', name: 'sausage' },
      { id: 'side-none', name: 'no side' }
    ]
  },
  httpOptions: {
    iframeTimeout: 7500
  },
  iframeMaxRetries: 4,
  desiredDateFormats: {
    dynamoFormat: 'yyyy-MM-dd',
    displayFormat: 'EEEE, MM/dd/yy'
  },
  sampleSale: {
    kitchenId: 'MORSE-ROAD',
    pickupDates: [new Date(2020, 8, 12), new Date(2020, 8, 13)],
    orderItemInput: {
      id: '966bf275-a2c8-44bb-844d-b63f1ea0f5b5',
      price: 75,
      name: 'L Brands Sample Sale',
      itemType: 'NONE',
      sides: []
    }
  }
};
